import cn from 'clsx';
import React, { useState } from 'react';

import { DropList } from '@/shared/components/input-search/types';
import { Button, IllustrationGame, Typography } from '@/shared/ui';
import { Illustration } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';

import styles from './drop-list-game.module.scss';
import { DropListGameProps } from './types';

const games: DropList[] = [
  {
    label: 'Adopt Me',
    value: 'adopt',
    icon: <IllustrationGame variant={'adopt'} size={32} />,
  },
  {
    label: 'MM2',
    value: 'mm2',
    icon: <IllustrationGame variant={'mm2'} size={32} />,
  },
];

export const DropListGame: React.FC<DropListGameProps> = ({ isMobile }) => {
  const [selectedGame, setSelectedGame] = useState<DropList>(games[0]);
  const [opened, setOpened] = useState(false);

  const headerClasses = cn(styles['container-header'], {
    [styles['container-header-opened']]: opened,
  });
  const iconClasses = cn(styles.icon, { [styles['icon-active']]: opened });

  const toggleClasses = cn(styles.game_item, styles.no_padding);

  const onSelect = (item: DropList) => {
    setSelectedGame(item);
    setOpened(false);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const renderGameItem = (item: DropList, index: number) => {
    return (
      <DropListItem
        key={index}
        isSelected={isMobile && item.value === selectedGame.value}
        variant={'game'}
        onPress={() => onSelect(item)}
        title={item.label}
        iconLeft={item.icon}
        className={styles.game_item}
      />
    );
  };

  return (
    <div className={styles.container}>
      <button className={headerClasses} onClick={() => setOpened(true)}>
        <DropListItem
          variant={'game'}
          iconLeft={selectedGame.icon}
          title={selectedGame?.label}
          className={toggleClasses}
          onPress={() => {}}
        />
        <Illustration
          spanTagClassName={iconClasses}
          name={'chevron-down'}
          style={{ color: '#BFBFBF' }}
          size={18}
          spriteName={'icons'}
        />
      </button>
      {isMobile ? (
        <BottomSheetUi isOpen={opened} handleClose={handleClose}>
          <BottomSheetUi.Content className={styles.sheet}>
            <BottomSheetUi.Header>Games</BottomSheetUi.Header>
            <div className={styles['sheet-items']}>
              {games.map(renderGameItem)}
            </div>
            <BottomSheetUi.Button text={'Close'} onClick={handleClose} />
          </BottomSheetUi.Content>
        </BottomSheetUi>
      ) : (
        <DropDownWrapper
          close={handleClose}
          isOpened={opened}
          className={styles.drop_down}
        >
          <>{games.map(renderGameItem)}</>
        </DropDownWrapper>
      )}
    </div>
  );
};
