'use client';
import {
  SidebarCategoryEntity,
  SidebarFilterEntity,
  SidebarSaleEntity,
} from '@entities/desktop/sidebars';
import { useQueryString } from '@hooks/client';
import {
  SidebarWidgetCoverProps,
  SidebarWidgetProps,
} from '@widgets/desktop/sidebar/ui/sidebar.types';
import cn from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { Filter } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { SidebarItem } from '@/shared/ui/desktop/sidebar-item';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { Icons } from '@/shared/ui/illustration/illustration';
import { useStore } from '@/store/context';

import styles from './sidebar.module.scss';

export const SIDEBAR_CATEGORIES_FILTERS: {
  topSlot: Filter[];
  bottomSlot: Filter[];
} = {
  topSlot: [
    {
      label: 'Pets',
      group: 'category',
      icon: 'cat',
      filter: 'pets',
      color: '#FE9920',
    },
    {
      label: 'Eggs',
      icon: 'egg',
      group: 'category',
      filter: 'eggs',
      color: '#D60F86',
    },
    {
      label: 'Potions',
      icon: 'potion',
      group: 'category',
      filter: 'potions',
      color: '#4891FF',
    },
    {
      label: 'Transport',
      icon: 'bus',
      group: 'category',
      filter: 'transport',
      color: '#6941C6',
    },
    {
      label: 'All',
      group: 'category',
      icon: 'widget',
      filter: 'all',
      color: '#6941C6',
    },
  ],
  bottomSlot: [
    {
      label: 'New',
      icon: 'lightning',
      group: 'category',
      filter: 'new',
      color: '#40BB18',
    },
    {
      label: 'Exclusive',
      icon: 'star',
      group: 'category',
      filter: 'exclusive',
      color: '#303030',
    },
    {
      label: 'Discounted Items',
      icon: 'ticket-sale',
      filter: 'discount',
      group: 'category',
      color: '#FE9920',
    },
    {
      label: 'Items Under $ 3.5',
      icon: 'tag',
      filter: 'cheap',
      group: 'category',
      color: '#4891FF',
    },
    /*    {
      label: 'X-MAS Sale',
      icon: 'cup-hot',
      filter: 'x-mas',
      group: 'category',
      color: '#EB3C3C',
    },*/
  ],
};

export const TRANSPORT_FILTERS: Filter[] = [
  { label: 'Scooters', filter: 'scooters', group: 'category' },
  { label: 'Skateboards', filter: 'skateboards', group: 'category' },
  { label: 'Snowboard', filter: 'snowboard', group: 'category' },
  {
    label: 'Legendary Gift Vehicle',
    filter: 'legendary-gift-vehicle',
    group: 'category',
  },
  { label: 'Event Vehicle', filter: 'event-vehicle', group: 'category' },
  {
    label: 'Vehicle Dealership',
    filter: 'vehicle-dealership',
    group: 'category',
  },
  { label: 'Premium Vehicle', filter: 'premium-vehicle', group: 'category' },
  {
    label: 'Star Reward Vehicle',
    filter: 'star-reward-vehicle',
    group: 'category',
  },
  {
    label: 'Miscellaneous Vehicle',
    filter: 'miscellaneous-vehicle',
    group: 'category',
  },
];

const typesByCategory = {
  Pets: null,
  Eggs: null,
  Transport: TRANSPORT_FILTERS,
  Potions: null,
  New: null,
  Exclusive: null,
  'Discounted Items': null,
  'Items Under $ 3.5': null,
  /*  'X-MAS Sale' :null*/
} as const;

type TypesByCategoryKeys = keyof typeof typesByCategory;

export const SidebarWidget = observer(
  ({ className, ...props }: SidebarWidgetProps) => {
    const sidebarStore = useStore().sidebar;
    const isSidebarOpened = sidebarStore.isSidebarOpened;

    const sidebarClasses = cn(
      styles['sidebar'],
      //{ [styles['closed']]: !isSidebarOpened },
      { [styles['opened']]: isSidebarOpened },
      className,
    );

    const [isCategorySelected, setIsCategorySelected] = useState<string | null>(
      null,
    );
    const handleSelectCategory = (category: string | null) => {
      setIsCategorySelected(category);
    };

    useEffect(() => {
      !isSidebarOpened && handleSelectCategory(null);
    }, [isSidebarOpened]);

    const sidebarVariants = {
      animate: {
        opacity: 1,
        x: 0,
        transition: {
          ease: 'linear',
          duration: 0.1,
          x: { duration: 0.1 },
        },
      },
      initial: {
        opacity: 1,
        zIndex: 2,
        x: '-100%',
        transition: {
          ease: 'linear',
          duration: 0.1,
        },
      },
      exit: {
        opacity: 1,
        x: '-100%',
        transition: {
          ease: 'linear',
          duration: 0.1,
        },
      },
    };

    const sidebarFilterVariants = {
      animate: {
        opacity: 1,
        x: 0,
        transition: {
          ease: 'linear',
          duration: 0.35,
          opacity: { duration: 0.5 },
          x: { duration: 0.35 },
        },
      },
      initial: {
        opacity: 0,
        x: '-50%',
      },
      exit: {
        opacity: 0,
        transition: {
          ease: 'linear',
          duration: 0.35,
          opacity: { duration: 0.35 },
        },
      },
    };

    const router = useRouter();
    const pathname = usePathname();

    const { createQueryString } = useQueryString();

    return (
      <>
        <motion.div
          animate={sidebarVariants.animate}
          exit={sidebarVariants.exit}
          initial={sidebarVariants.initial}
          variants={sidebarVariants}
          id={`sidebar--${isSidebarOpened ? 'opened' : 'closed'}`}
          className={sidebarClasses}
        >
          <div className={styles['content']}>
            <SidebarCategoryEntity
              topSlot={
                <>
                  {SIDEBAR_CATEGORIES_FILTERS?.topSlot?.map((item, idx) => {
                    return (
                      <SidebarItem
                        active={isCategorySelected === item.label}
                        showArrow={
                          !!typesByCategory[
                            isCategorySelected as TypesByCategoryKeys
                          ]
                        }
                        key={`${idx}-sidebar-cat-${item.label}`}
                        width={'full-w'}
                        onMouseEnter={() => handleSelectCategory(item.label)}
                        text={item.label}
                        onClick={() => {
                          if (item.filter === 'all') {
                            router.push('/categories');
                          } else {
                            router.push(
                              createQueryString({
                                pathname: '/shop',
                                name: 'category',
                                value: item.filter,
                                asMultipleParams: false,
                              }),
                            );
                          }
                          sidebarStore.toggleSidebar();
                        }}
                        icon={
                          <Illustration
                            id={'sidebar-icon'}
                            style={{ color: item.color }}
                            name={item.icon as keyof Icons}
                            spriteName={'icons'}
                            size={22}
                          />
                        }
                      />
                    );
                  })}
                </>
              }
              bottomSlot={
                <>
                  {SIDEBAR_CATEGORIES_FILTERS?.bottomSlot?.map((item, idx) => {
                    return (
                      <SidebarItem
                        active={isCategorySelected === item.label}
                        key={`sidebar-${idx}-cat-${item.label}`}
                        width={'full-w'}
                        onMouseEnter={() => handleSelectCategory(item.label)}
                        text={item.label}
                        onClick={() => {
                          router.push(
                            createQueryString({
                              pathname: '/shop',
                              name: 'category',
                              value: item.filter,
                              asMultipleParams: false,
                            }),
                          );
                          sidebarStore.toggleSidebar();
                        }}
                        icon={
                          <Illustration
                            id={'sidebar-icon'}
                            style={{ color: item.color }}
                            name={item.icon as keyof Icons}
                            spriteName={'icons'}
                            size={22}
                          />
                        }
                      />
                    );
                  })}
                </>
              }
            />

            {isCategorySelected &&
              typesByCategory[isCategorySelected as TypesByCategoryKeys] && (
                <motion.div
                  animate={sidebarFilterVariants.animate}
                  exit={sidebarFilterVariants.exit}
                  initial={sidebarFilterVariants.initial}
                  variants={sidebarFilterVariants}
                >
                  <SidebarFilterEntity
                    title={'Type'}
                    className={cn(styles['filters'])}
                  >
                    {typesByCategory[
                      (isCategorySelected as TypesByCategoryKeys) ?? 'Pets'
                    ]?.map(item => (
                      <DropListItem
                        className={styles['droplist-item']}
                        key={`sidebar-filter-${item.label}`}
                        title={item.label}
                        onPress={() => {
                          router.push(
                            createQueryString({
                              pathname: '/shop',
                              name: item.group,
                              value: item.filter,
                              asMultipleParams: false,
                            }),
                          );
                          sidebarStore.toggleSidebar();
                        }}
                      />
                    ))}
                  </SidebarFilterEntity>
                </motion.div>
              )}

            {/*{isCategorySelected &&*/}
            {/*    <motion.div*/}
            {/*      animate={sidebarFilterVariants.animate}*/}
            {/*      exit={sidebarFilterVariants.exit}*/}
            {/*      //initial={sidebarFilterVariants.initial}*/}
            {/*      variants={sidebarFilterVariants}>*/}
            {/*      <SidebarSaleEntity*/}
            {/*        title={'Skateboard sale!'}*/}
            {/*        //imgSrc={'/assets/drop-list-game/adopt.webp'}*/}
            {/*        className={cn(styles['filters'])}*/}
            {/*        subtitle={'Skateboards and other trasport with 30% price off'}*/}
            {/*        bottomSlot={*/}
            {/*          <Button*/}
            {/*            onClick={() => {}}*/}
            {/*            iconRight={*/}
            {/*              <Illustration*/}
            {/*                name={'cart-plus'}*/}
            {/*                spriteName={'icons'}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            variant={'primary'}*/}
            {/*            text={'Add to cart'}*/}
            {/*            className={styles['add-button']}*/}
            {/*          />*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </motion.div>}*/}
          </div>
        </motion.div>

        <SidebarWidgetCover
          isSidebarOpened={isSidebarOpened}
          onClick={() => {
            sidebarStore.setIsOpened(false);
          }}
        />
      </>
    );
  },
);

const SidebarWidgetCover = ({
  isSidebarOpened,
  ...props
}: SidebarWidgetCoverProps) => {
  return isSidebarOpened ? (
    <div className={cn(styles.cover)} {...props} />
  ) : null;
};
